import React, { Component } from "react";
import styled from "styled-components";
import SocialDataMobile from "../data/SocialDataMobile";
import SocialData from "../data/SocialData";

const DisplaySize = 870 - 1;

const SectionsDesktop = styled.div`
  @media (max-width: ${DisplaySize}px) {
    display: none;
  }
  @media not all and (max-width: ${DisplaySize}px) {
    display: flex;
  }
`;

const SectionsMobile = styled.div`
  @media not all and (max-width: ${DisplaySize}px) {
    display: none;
  }
  @media (max-width: ${DisplaySize}px) {
    display: flex;
  }
`;

// -------------------------------------- Caja Botones social
const CajaSocial = styled.div`
  margin: auto;
  width: fit-content;
`;

// -------------------------------------- Iconitos Social
const Link = styled.a`
  display: inline-block;
  border: 2.5px solid white;
  border-radius: 20px;
  opacity: 0.8;
  padding: 6px;
  margin: 6px;
  height: 42px;
  width: fit-content;

  &:hover {
    transition: opacity 0.25s;
    opacity: 1;
  }
`;

const Icon = styled.div`
  background: url(${(props) => props.Social}) no-repeat;
  background-size: cover;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(305deg) brightness(102%) contrast(102%);
  height: 25px;
  min-width: 25px;
`;

export class SocialMap extends Component {
  render() {
    // el className = "AboutMe" está en el archivo css porque en styled no funciona
    const SocialMapDesktop = SocialData.map((item) => (
      <Link key={item.name} href={item.href} title={item.title}>
        <Icon
          className={item.name}
          Social={item.icon}
          onClick={() => this.props.handleClick(item.name)}
        ></Icon>
      </Link>
    ));
    const SocialMapMobile = SocialDataMobile.map((item) => (
      <Link key={item.name} href={item.href} title={item.title}>
        <Icon
          className={item.name}
          Social={item.icon}
          onClick={() => this.props.handleClick(item.name)}
        ></Icon>
      </Link>
    ));
    return (
      <CajaSocial>
        <SectionsDesktop> {SocialMapDesktop}</SectionsDesktop>
        <SectionsMobile> {SocialMapMobile}</SectionsMobile>
      </CajaSocial>
    );
  }
}
