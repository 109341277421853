import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import * as emailjs from "emailjs-com";
import ScreenMessage from "../../components/ScreenMessage";

class Contact extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    successmsg: "",
  };

  handleSuccessMessage = (msg) => {
    this.setState({
      successmsg: msg,
    });
    setTimeout(() => {
      this.setState({
        successmsg: "",
      });
    }, 4000);
  };
  handleErrorMessage = (msg) => {
    this.setState({
      errormsg: msg,
    });
    setTimeout(() => {
      this.setState({
        errormsg: "",
      });
    }, 4000);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = this.state;

    if (!name || !email || !message) {
      this.handleErrorMessage("Debe rellenar todos los datos");
    } else {
      var template_params = {
        to_name: "dev.juangutierreza.com",
        nombre: name,
        email: email,
        message: message,
      };

      var service_id = "koalavanidoso";
      var template_id = "template_al8stbn";
      emailjs.send(service_id, template_id, template_params, "user_XBvKU8NUV9sGZ17LcPSA5");
      this.resetForm();

      this.handleSuccessMessage("Mensaje enviado con éxito ¡Gracias!");
    }
  };

  resetForm = () => {
    this.setState({
      name: "",
      email: "",
      message: "",
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className="banner color">
        <h2 className="underlined color">Contacta conmigo</h2>
        {this.state.successmsg || this.state.errormsg ? (
          <ScreenMessage successmsg={this.state.successmsg} errormsg={this.state.errormsg} />
        ) : (
          ""
        )}
        <Form widths="equal" onSubmit={this.handleSubmit} className="contact form">
          <Form.Group>
            <Form.Input
              label="Nombre"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
              placeholder="Nombre"
            ></Form.Input>
            <Form.Input
              label="Correo electrónico"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="ejemplo@mail.com"
            ></Form.Input>
            {/* <Form.Input
              label="Apellido"
              name="surname"
              value={this.state.surname}
              onChange={this.handleChange}
              placeholder="Apellido"
            ></Form.Input> */}
          </Form.Group>

          <Form.TextArea
            rows={10}
            label="Mensaje"
            name="message"
            value={this.state.message}
            onChange={this.handleChange}
          ></Form.TextArea>
          <Form.Button color="green" onClick={this.handleSubmit}>
            Enviar
          </Form.Button>
        </Form>
      </div>
    );
  }
}

export default Contact;
