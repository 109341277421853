import axios from "axios";

// Login User
export const loginAction = (email, password) => {
  // Headers
  var body = {
    email: email,
    password: password,
  };

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  };

  axios
    .post(`${process.env.REACT_APP_API_URL}/login`, body, axiosConfig)
    .then((res) => {
      console.log("RESPONSE RECEIVED: ", res);
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      console.log("msg: ", err.response.data.msg);
    });
};
