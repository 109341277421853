import React, { Component } from "react";
import SkillsData from "../../data/SkillsData";
import styled from "styled-components";

const ImgContainer = styled.div`
  margin: auto;
  height: 160px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
`;

class Skills extends Component {
  render() {
    return (
      <div className="skillsSection">
        {SkillsData.map((item) => (
          <div key={item.name} className="skill Item">
            <ImgContainer>
              <img alt={item.name} src={item.image} />
            </ImgContainer>
            <h3>{item.name}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    );
  }
}

export default Skills;
