import React, { Component } from "react";

import styled from "styled-components";
import { Input } from "semantic-ui-react";

const CajaConTodo = styled.div`
  background-color: #09d3ac;
  flex-basis: 100%;
  flex-flow: column;
  flex-grow: 1;
  overflow: auto;
`;

class Gas extends Component {
  state = {
    tripDistance: null,
    averageFuel: null,
    fuelPrice: null,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { tripDistance, averageFuel, fuelPrice } = this.state;

    const TripFuel = (tripDistance * averageFuel) / 100;

    const TripCost = (TripFuel * fuelPrice).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return (
      <CajaConTodo>
        <div className="Centered">
          <Input
            className="InputSize"
            name="tripDistance"
            label={{ basic: true, content: "km" }}
            labelPosition="right"
            placeholder="Enter trip distance..."
            onChange={this.onChange}
          />
          <br />
          <Input
            className="InputSize"
            name="averageFuel"
            label={{ basic: true, content: "l/100km" }}
            labelPosition="right"
            placeholder="Enter average fuel..."
            onChange={this.onChange}
          />
          <br />
          <Input
            className="InputSize"
            name="fuelPrice"
            label={{ basic: true, content: "€/l" }}
            labelPosition="right"
            placeholder="Enter fuel price..."
            onChange={this.onChange}
          />

          <br />

          <h2>Litros: {tripDistance && averageFuel ? TripFuel + "l" : ""}</h2>
          <h2>Gasto: {TripFuel && fuelPrice ? TripCost + "€" : ""}</h2>
        </div>
      </CajaConTodo>
    );
  }
}
export default Gas;
