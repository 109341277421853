import React, { Component } from "react";

class FotoAcirfa extends Component {
  componentDidMount() {
    window.location.href =
      "https://pinguino.juangutierreza.com/fotoacirfarural";
  }
  render() {
    return <></>;
  }
}

export default FotoAcirfa;
