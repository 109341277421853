const languageReducer = (state = "", action) => {
  switch (action.type) {
    case "ES":
      return (state = "ES");
    case "EN":
      return (state = "EN");
    default:
      return (state = "EN");
  }
};

export default languageReducer;
