import React, { Component } from "react";
import styled from "styled-components";
import LanguageModal from "../components/SingTheWord/LanguageModal";
import WordsEnglish from "../data/WordsEnglish";
import WordsSpanish from "../data/WordsSpanish";

const AppBlock = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const CajaConTodo = styled.div`
  background-color: #138D75;
  ----;
  display: flex;
  flex-flow: column;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const CajaCentrada = styled.div`
  margin: auto;
  text-align: center;
`;

const Circle = styled.div`
  background-color: white;
  width: 300px;
  height: 300px;
  border-radius: 150px;
  display: flex;
  z-index: 1;

  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;

  &.none {
    display: none;
  }

  &.transition {
    animation-name: grow;
    animation-duration: 0.75s;
    animation-iteration-count: 1;
  }

  @keyframes grow {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(3);
    }
    100% {
      transform: scale(0);
    }
  }
`;

const Button = styled.button`
  background: #138d75;
  border-radius: 20px;
  border: 2px solid #138d75;
  color: white;
  padding: 0.5em 0.6em;
  font-size: 40px;
  margin: auto;
`;

const Texto = styled.h1`
  text-transform: uppercase;
  color: white;
  font-size: 45px;
  margin: auto;
  text-align: center;
  z-index: 2;
`;

// ***************************************** THIS FUNCTION RETURNS THE ARRAY IN A RANDOM NEW ORDER
function Randomise(a, b) {
  return 0.5 - Math.random();
}

class SingTheWord extends Component {
  state = {
    Language: null,
    Instructions: false,
    Words: [],
    Start: false,
    WordIndex: 0,
    WordsFinished: false,
    CircleTransition: false,
  };

  handleWords = () => {
    if (this.state.Language === "ES") {
      this.setState({
        Words: WordsSpanish.sort(Randomise),
      });
    } else if (this.state.Language === "EN") {
      this.setState({
        Words: WordsEnglish.sort(Randomise),
      });
    }
  };

  handleInstructions = (state) => {
    this.setState({ Instructions: state });
  };

  handleLanguageButton = (lang) => {
    this.setState({
      Language: lang,
      Instructions: true,
    });
  };

  // FALTA ALGO QUE DEJE EL TEXTO DETRÁS!!!
  handleStartButton = () => {
    this.handleWords(); // Mezcla y carga las palabras
    this.setState({ Start: true, CircleTransition: true });
    setTimeout(() => {
      this.setState({ CircleTransition: false });
    }, 750);
  };

  handleNextWord = () => {
    const { Words, WordIndex } = this.state;

    // SI ES LA ÚLTIMA PALABRA
    const Total = Words.length - 1;
    if (WordIndex === Total) {
      this.setState({
        WordsFinished: true,
      });
    }
    // SI NO ES LA ÚLTIMA PALABRA :)
    else {
      this.setState({ WordIndex: WordIndex + 1 });
    }
  };

  render() {
    const { Language, Instructions, Start, WordIndex, Words, WordsFinished, CircleTransition } =
      this.state;

    const ENContent = {
      finishedText: "We've ran out of words!! 🥳",
      finishedText2: "You've completed every word!! 🥳",
      header: "Welcome!",
      text1: "🎤 In this game you must sing a song that contains the next words 🎤",
      text2: "Tap the screen to change the word",
      // text3: "Click the button 'START' when you are ready!",
      buttonText: "Got it! 😉",
      startButtonText: "START",
    };

    const ESContent = {
      finishedText: "!!Has acabado las palabras!! 🥳",
      header: "¡Bienvenido!",
      text1: "🎤 Este juego consiste en cantar una canción que contenga la palabra mostrada 🎤",
      text2: "Toca la pantalla para pasar a la siguiente palabra",
      // text3: "Pulsa el botón 'EMPEZAR' cuando estés listo!",
      buttonText: "Lo tengo! 😉",
      startButtonText: "EMPEZAR",
    };

    var LangContent = Language === "ES" ? ESContent : ENContent;

    const StartButton = (
      <>
        <Circle className={CircleTransition ? "transition" : Start ? "none" : ""}>
          {CircleTransition ? (
            ""
          ) : (
            <Button className="Text" onClick={this.handleStartButton}>
              {LangContent.startButtonText}
            </Button>
          )}
        </Circle>
      </>
    );

    const Content = (
      <>
        <Texto className="Text">
          {WordsFinished ? LangContent.finishedText : Words[WordIndex]}
        </Texto>
      </>
    );

    return (
      <>
        <LanguageModal
          Language={Language}
          handleLanguageButton={this.handleLanguageButton}
          Instructions={Instructions}
          handleInstructions={this.handleInstructions}
          LangContent={LangContent}
        />
        <AppBlock
          onClick={this.handleNextWord}
          style={Start ? { zIndex: 9, position: "absolute" } : { zIndex: 0, position: "relative" }}
        />
        <CajaConTodo>
          {StartButton}
          <CajaCentrada>{Start ? Content : ""}</CajaCentrada>
        </CajaConTodo>
      </>
    );
  }
}

export default SingTheWord;

/*
        <Circle className="CircleAnimation">


*/
