import React, { Component } from "react";
import styled from "styled-components";
import AboutMe from "../components/AboutMe";
import Avatar from "../components/Avatar";

const DisplaySize = 870;

const CajaConTodo = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  margin: auto;

  @media (max-width: ${DisplaySize}px) {
    overflow: auto;
    ::-webkit-scrollbar {
      background-color: transparent;
    }
  }
`;

const ProfileBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.7s;
  padding: 10px;

  @media (max-width: ${DisplaySize}px) {
    width: 100%;
    height: auto;
  }
  @media (min-width: ${DisplaySize}px) {
    height: 100%;
    float: left;
    width: 100%;
    min-width: 240px;

    width: ${(props) => (props.openAbout ? "38%" : "100%")};
  }
`;

const AboutMeBox = styled.div`
  background-color: var(--main-color2);
  transition: all 0.7s;
  height: 100%;

  @media (max-width: ${DisplaySize}px) {
    width: 100%;
    height: auto;
    flex-flow: column;
  }

  @media (min-width: ${DisplaySize}px) {
    margin-left: 100%;
    width: 62%;
    margin-left: ${(props) => (props.openAbout ? "38%" : "100%")};
  }
`;

class Inicio extends Component {
  state = {
    openAbout: false,
  };

  handleOpenAbout = () => {
    this.setState({ openAbout: !this.state.openAbout });
  };

  render() {
    return (
      <CajaConTodo>
        <ProfileBox openAbout={this.state.openAbout}>
          <Avatar handleOpenAbout={this.handleOpenAbout} />
        </ProfileBox>
        <AboutMeBox openAbout={this.state.openAbout}>
          <AboutMe />
        </AboutMeBox>
      </CajaConTodo>
    );
  }
}

export default Inicio;
