const WordsSpanish = [
  "cabeza",
  "Barcelona",
  "por favor",
  "me gusta",
  "rodar",
  "hacer",
  "odio",
  "baile",
  "vela",
  "corazón",
  "perro",
  "casa",
  "lento",
  "despacio",
  "rápido",
  "gato",
  "reunir",
  "reunió",
  "construir",
  "sonreír",
  "triste",
  "sacudir",
  "girar",
  "correr",
  "escalada",
  "nubes",
  "hermana",
  "hermano",
  "madre",
  "padre",
  "papi",
  "mamá",
  "abuelo",
  "abuela",
  "mamá",
  "cielo",
  "demonio",
  "pecados",
  "iglesia",
  "ángel",
  "show",
  "respirar",
  "noche",
  "Mañana",
  "hoy",
  "día",
  "ayer",
  "dulce",
  "uno",
  "solamente",
  "espalda",
  "malo",
  "bueno",
  "abajo",
  "arriba",
  "pequeña",
  "grande",
  "teléfono",
  "moneda",
  "belleza",
  "hermoso",
  "constituir",
  "tarde",
  "temprano",
  "Vamos",
  "ven",
  "Quédate",
  "para siempre",
  "nunca",
  "decir",
  "dijo",
  "arcoíris",
  "algún",
  "lugar",
  "peligroso",
  "reina",
  "rey",
  "corona",
  "miel",
  "facturas",
  "dinero",
  "loco",
  "profundo",
  "otra",
  "roto",
  "dama",
  "Caballero",
  "maldita",
  "policía",
  "bomberos",
  "hombre",
  "hombro",
  "mujer",
  "muchachas",
  "criatura",
  "suerte",
  "perdió",
  "encontrado",
  "ver",
  "todos",
  "Quemadura",
  "incendio",
  "alguien",
  "saber",
  "lo siento",
  "disculparse",
  "guerra",
  "palabra",
  "canción",
  "anillo",
  "vamos",
  "azúcar",
  "cuidado",
  "tener",
  "necesidad",
  "Dios",
  "juntos",
  "mejor",
  "imagina",
  "gratis",
  "libertad",
  "divertido",
  "Oceano",
  "pastel",
  "manzana",
  "color",
  "Estimado",
  "rosas",
  "come",
  "amar",
  "orar",
  "alimento",
  "DJ",
  "tierra",
  "Marte",
  "vida",
  "universo",
  "mundo",
  "Dieciocho",
  "Siete",
  "dieciseis",
  "estrellas",
  "julio",
  "venda",
  "cerrar",
  "Mira",
  "Beso",
  "llave",
  "amado",
  "planes",
  "vaqueros",
  "rostro",
  "niñ@",
  "preocuparse",
  "cielo",
  "vestir",
  "perfecto",
  "amigo",
  "fuego",
  "andar",
  "memoria",
  "recuerdo",
  "árbol",
  "usted",
  "me",
  "yo",
  "fotografía",
  "casa",
  "imagen",
  "puerta",
  "tesoro",
  "ver",
  "dorado",
  "gelatina",
  "huele",
  "bebé",
  "ventana",
  "gracias",
  "pueblo",
  "carretera",
  "camino",
  "montar",
  "mantener",
  "pelo",
  "clavos",
  "infierno",
  "Zapatos",
  "tacones",
  "luz",
  "oscuro",
  "piso",
  "ojo",
  "trabaja",
  "piel",
  "forma",
  "marrón",
  "nombre",
  "negro",
  "amarillo",
  "azul",
  "manos",
  "nombre",
  "esperar",
  "alegría",
  "camisa",
  "falda",
  "sueño",
  "fiesta",
  "mejor amig@",
  "húmedo",
  "soleado",
  "fin de semana",
  "lunes",
  "viernes",
  "casar",
  "gente",
  "brillante",
  "lado",
  "rojo",
  "detener",
  "California",
  "monstruo",
  "jugar",
  "verano",
  "crecer",
  "nieve",
  "Navidad",
  "llora",
  "castillo",
  "príncipe",
  "princesa",
  "luna",
];

export default WordsSpanish;
