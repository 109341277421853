const WordsEnglish = [
  "head",
  "barcelona",
  "please",
  "like",
  "roll",
  "make",
  "hate",
  "dance",
  "candle",
  "heart",
  "dog",
  "home",
  "slow",
  "slowly",
  "fast",
  "cat",
  "meet",
  "met",
  "build",
  "smile",
  "sad",
  "shake",
  "twist",
  "run",
  "climb",
  "clouds",
  "sister",
  "brother",
  "mother",
  "dad",
  "daddy",
  "mom",
  "grandpa",
  "grandma",
  "mama",
  "heaven",
  "devil",
  "sins",
  "church",
  "angel",
  "show",
  "breathe",
  "night",
  "morning",
  "today",
  "yesterday",
  "sweet",
  "one",
  "only",
  "back",
  "bad",
  "good",
  "down",
  "up",
  "small",
  "big",
  "phone",
  "telephone",
  "payphone",
  "beauty",
  "beautiful",
  "make up",
  "late",
  "early",
  "go",
  "come",
  "stay",
  "forever",
  "never",
  "say",
  "told",
  "rainbow",
  "somewhere",
  "dangerous",
  "queen",
  "king",
  "crown",
  "honey",
  "bills",
  "money",
  "crazy",
  "deep",
  "again",
  "broken",
  "lady",
  "gentleman",
  "damn",
  "police",
  "firemen",
  "man",
  "woman",
  "girls",
  "creature",
  "luck",
  "lucky",
  "lost",
  "found",
  "see",
  "all",
  "burn",
  "burning",
  "somebody",
  "know",
  "sorry",
  "apologise",
  "war",
  "word",
  "song",
  "ring",
  "come on",
  "sugar",
  "care",
  "have",
  "need",
  "god",
  "together",
  "better",
  "imagine",
  "free",
  "freedom",
  "fun",
  "ocean",
  "cake",
  "apple",
  "colour",
  "dear",
  "roses",
  "eat",
  "love",
  "pray",
  "food",
  "DJ",
  "earth",
  "mars",
  "life",
  "universe",
  "world",
  "eighteen",
  "seven",
  "seventeen",
  "stars",
  "july",
  "lock",
  "look",
  "kiss",
  "key",
  "loved",
  "plans",
  "jeans",
  "face",
  "child",
  "worry",
  "sky",
  "dress",
  "perfect",
  "friend",
  "fire",
  "walk",
  "memory",
  "tree",
  "you",
  "me",
  "I",
  "photograph",
  "house",
  "picture",
  "door",
  "treasure",
  "see",
  "golden",
  "jelly",
  "smell",
  "baby",
  "window",
  "thank",
  "town",
  "road",
  "ride",
  "hold",
  "hair",
  "nails",
  "hell",
  "shoes",
  "high heels",
  "light",
  "dark",
  "floor",
  "eye",
  "work",
  "skin",
  "shape",
  "brown",
  "name",
  "black",
  "yellow",
  "blue",
  "hand/s",
  "name",
  "hope",
  "joy",
  "shirt",
  "skirt",
  "dream",
  "party",
  "bestie",
  "wet",
  "sunny",
  "weekend",
  "monday",
  "friday",
  "marry",
  "people",
  "bright",
  "side",
  "red",
  "stop",
  "california",
  "monster",
  "play",
  "summer",
  "grow",
  "snow",
  "christmas",
  "cry",
  "hill",
  "castle",
  "prince",
  "princess",
  "moon",
];

export default WordsEnglish;
