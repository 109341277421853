import React from "react";
import { Button, Modal } from "semantic-ui-react";

function InstructionsContent(props) {
  return (
    <Modal.Content className="instructionsModal">
      <h1>{props.LangContent.header}</h1>
      <h4>{props.LangContent.text1}</h4>
      <h4>{props.LangContent.text2}</h4>
      {/* <h4>{props.LangContent.text3}</h4> */}
      <Button
        className="instructionsButton"
        size="massive"
        active
        onClick={() => props.handleInstructions(false)}
      >
        {props.LangContent.buttonText}
      </Button>
    </Modal.Content>
  );
}

function LanguageContent(props) {
  return (
    <Modal.Content className="languageModal">
      <Button
        className="languageButton"
        size="massive"
        active
        onClick={() => props.handleLanguageButton("ES")}
      >
        🇪🇸 Español
      </Button>
      <Button
        className="languageButton"
        size="massive"
        active
        onClick={() => props.handleLanguageButton("EN")}
      >
        🇬🇧 English
      </Button>
    </Modal.Content>
  );
}

function LanguageModal(props) {
  return (
    <Modal
      dimmer="inverted"
      className="modal"
      size="small"
      open={!props.Language || props.Instructions ? true : false}
    >
      {!props.Language ? (
        <LanguageContent handleLanguageButton={props.handleLanguageButton} />
      ) : (
        <InstructionsContent
          handleInstructions={props.handleInstructions}
          LangContent={props.LangContent}
        />
      )}
    </Modal>
  );
}

export default LanguageModal;
