import GitIMG from "../assets/skills/git.png";
import JavaIMG from "../assets/skills/java.png";
import ReactIMG from "../assets/skills/reactjs.png";
import NodeIMG from "../assets/skills/nodejs.png";
import MongoIMG from "../assets/skills/mongodb.png";
import MysqlIMG from "../assets/skills/mysql.png";
// import SemanticIMG from "../assets/skills/semantic.png";
// import BootstrapIMG from "../assets/skills/bootstrap.png";
import StyledComponentsIMG from "../assets/skills/styled-components.png";
import PhpIMG from "../assets/skills/php.png";

// NETLIFY, DOCKER (?)

const SkillsData = [
  {
    name: "React.js",
    description:
      "ReactJS es la primera tecnología que aprendí y con la que más familiarizado estoy. Esta web está basada en React",
    image: ReactIMG,
  },
  {
    name: "styled-components",
    description: "Utilizo styled-components para hacer el diseño de componentes en React",
    image: StyledComponentsIMG,
  },
  {
    name: "Node.js",
    description: "Utilizo Node.js para entorno servidor",
    image: NodeIMG,
  },
  {
    name: "PHP",
    description: "He cursado PHP y he trabajado migrando código legacy de PHP a React y Node",
    image: PhpIMG,
  },

  {
    name: "Java",
    description: "He cursado Java aunque no lo utilizo en el día a día",
    image: JavaIMG,
  },
  {
    name: "Git",
    description: "Utilizo Git para tener control de versiones y trabajar en equipo",
    image: GitIMG,
  },
  // {
  //   name: "Semantic-React",
  //   description: "Utilizo Semantic desde que empecé a programar en React para hacer el diseño",
  //   image: SemanticIMG,
  // },
  // {
  //   name: "Bootstrap 4",
  //   description:
  //     "Estoy empezando a utilizar Bootstrap por las facilidades que aporta y su rapidez creando el diseño de una web",
  //   image: BootstrapIMG,
  // },
  {
    name: "MongoDB",
    description:
      "Es la base de datos no relacional que utilizo cuando encaja con las necesidades del proyecto",
    image: MongoIMG,
  },
  {
    name: "MySQL",
    description: "Empleo MySQL para mis bases de datos relacionales",
    image: MysqlIMG,
  },
];
export default SkillsData;
