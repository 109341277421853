import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { loginAction } from "../actions/loginAction";

class Login extends Component {
  state = {
    email: "",
    password: "",
    msg: null,
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    loginAction(email, password);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <>
        <Form onSubmit={this.onSubmit}>
          <Form.Input label="Email" name="email" placeholder="email" onChange={this.onChange} />

          <Form.Input
            label="Password"
            type="password"
            name="password"
            placeholder="Contraseña"
            onChange={this.onChange}
          />
          <Form.Button primary content="Iniciar sesión" />
        </Form>
      </>
    );
  }
}

export default Login;
