import React, { Component } from "react";

import styled from "styled-components";
import Login from "../components/Login";
import { Grid } from "semantic-ui-react";

const CajaConTodo = styled.div`
  background-color: #09d3ac;
  flex-basis: 100%;
  flex-flow: column;
  flex-grow: 1;
  overflow: auto;
`;

class Testing extends Component {
  render() {
    return (
      <CajaConTodo>
        <Grid columns="equal" centered>
          <Login></Login>
        </Grid>
      </CajaConTodo>
    );
  }
}
export default Testing;
