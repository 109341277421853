import React, { Component } from "react";
import styled from "styled-components";
// import Avatarimg from "../assets/Other/Me.jpeg";
import Avatarimg from "../assets/avatar.jpg";
import { SocialMap } from "./SocialMap";

const CajaConTodo = styled.div`
  text-align: center;
  margin: auto;
`;

// -------------------------------------- Caja Foto
const Photo = styled.div`
  display: inline-block;
  background: url(${Avatarimg}) no-repeat;
  background-size: cover;
  border: 3px solid white;
  border-radius: 50%;
  padding: 100px;

  height: 100px;
  width: 100px;
`;

// -------------------------------------- Divider
const Divider = styled.hr`
  display: block;
  width: 25%;
  max-width: 600px;
  margin: 20px auto;
  border: 0.5px solid white;
  border-radius: 50%;
`;

class Avatar extends Component {
  handleClick = (name) => {
    if (name === "AboutMe") {
      this.props.handleOpenAbout();
    }
  };

  render() {
    return (
      <CajaConTodo>
        <Photo />
        <h1 style={{ color: "white" }}>Juan Gutiérrez</h1>
        <Divider />
        <SocialMap handleClick={this.handleClick} />
      </CajaConTodo>
    );
  }
}

export default Avatar;

/* 
  background: url(${imagenfondo}) no-repeat;
  background-attachment: scroll;
  background-clip: border-box;
  background-position: center;
  background-size: cover;

*/
