import React, { Component } from "react";
import qrgenIMG from "../../assets/projects/qrgen.png";
import singthewordIMG from "../../assets/projects/singtheword.png";

class MyProjects extends Component {
  render() {
    const ProjectsData = [
      {
        key: "8",
        name: "Sing the Word",
        text: "Sing the Word",
        href: "/singtheword",
        imgsrc: singthewordIMG,
      },
      {
        key: "9",
        name: "QR Gen",
        text: "QR Generator",
        href: "/qrgen",
        imgsrc: qrgenIMG,
      },
    ];

    const Projects = ProjectsData.map((item) => {
      return (
        <div key={item.key} className="project">
          <a href={item.href}>{item.text}</a>
          <img src={item.imgsrc} href={item.href} alt={item.text}></img>
        </div>
      );
    });
    return (
      <div className="banner border projectSection">
        <h2 className="underlined" style={{ width: "100%" }}>
          Algunos de mis proyectos
        </h2>

        {Projects}
      </div>
    );
  }
}

export default MyProjects;
