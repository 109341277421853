import React, { Component } from "react";
import styled from "styled-components";
import About from "./AboutMe/About.js";
// import Resume from "./AboutMe/Resume";
import Skills from "./AboutMe/Skills";
import Contact from "./AboutMe/Contact";
import MyProjects from "./AboutMe/MyProjects";

const DisplaySize = 870;

const CajaConTodo = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 30px;
  @media (min-width: ${DisplaySize}px) {
    overflow: auto;
  }
`;

class AboutMe extends Component {
  render() {
    return (
      <CajaConTodo>
        <About />
        <Skills />
        <MyProjects />
        <Contact />
      </CajaConTodo>
    );
  }
}

export default AboutMe;

// <Label>Work in progress :)</Label>

/*
VERY IMPORTANT PARA CENTRAR
display: flex;
justify-content: center;      Centra horizontalmente
align-items: center;          Centra verticalmente
*/

// import { MenuWithRouter } from "./Menu";
// <MenuWithRouter />
