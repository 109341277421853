import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="banner color about">
        <h2>Sobre mí</h2>
        <p className="textalign left">
          Soy desarrollador web de React.js, Node.js y MySQL.
          <br />
          He cursado JavaScript, Java, PHP y MySQL en el módulo de grado superior 'Desarrollo de aplicaciones web'.
          <br />
          <br />
          Actualmente soy desarrollador front-end en <a href="https://www.finect.com" target="_blank" rel="noreferrer" >finect.com</a>
        </p>
      </div>
    );
  }
}

export default About;
